import React, { Fragment } from 'react';
import DSMSearchInput from '../DSMSearchInput/DSMSearchInput';

function DSMSearch() {
  // const hiddenColumns = ['status'];
  return (
    <Fragment>
      <DSMSearchInput />
    </Fragment>
  );
}

export default DSMSearch;
